/*
    Credits:
    ReactJS - Meta Open Source - https://react.dev/
    PDFMake - Bartek Pampuch - http://pdfmake.org/
*/

import React, {Component} from "react";
import '../../assets/css/secondary-page.css';
import '../../assets/css/all-pages.css';
import '../../assets/css/bingo-card-maker.css';
import Navbar from '../../components/navbar';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class BingoCardMaker extends Component<{}, {
    termArray: string[];
    freeSpace: string;
    freeSpaceIndex: number;
    numberOfPages: number;
    title: string;
}> {

    worker: Worker | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            termArray: [],
            freeSpace: "",
            freeSpaceIndex: -1,
            numberOfPages: 1,
            title: "BINGO",
        };
    }


    handleKeyDown(event: { key: string; }): void {
        if (event.key === 'Enter') {
            this.addTerm();
        }
    }

    addTerm() {
        const termInput = document.querySelector("#bingo-term-input") as HTMLInputElement;
        const term = termInput.value.trim();
        if (term === "") return;

        this.state.termArray.push(term);
        this.setState({termArray: this.state.termArray});
        termInput.value = "";

        this.updateBoxes();
    }

    removeTerm(term: string) {
        if (!this.state.termArray.includes(term)) return;
        if (this.state.freeSpace === term) {
            this.removeFreeSpace();
        }

        const index = this.state.termArray.indexOf(term);
        if (index < this.state.freeSpaceIndex) {
            this.setState({freeSpaceIndex: this.state.freeSpaceIndex - 1});
        }
        this.state.termArray.splice(index, 1);
        this.setState({termArray: this.state.termArray});
        if (index === this.state.termArray.length)
            this.termEndHover();
        setTimeout(() => this.updateBoxes());
    }

    setFreeSpace(term: string, index: number) {
        this.removeFreeSpace();
        if (!this.state.termArray.includes(term)) return;
        if (index === this.state.freeSpaceIndex) {
            setTimeout(() => this.updateBoxes());
            return;
        }
        this.setState({freeSpace: term, freeSpaceIndex: index});
        const button = document.querySelector(`#full-term-list`)?.childNodes[index].childNodes[2] as HTMLButtonElement;
        button.classList.add("free-space-active");
        document.querySelector(`#bingo-box-12`)?.classList.add("free-space-set");
        setTimeout(() => this.updateBoxes());
    }

    removeFreeSpace() {
        this.setState({freeSpace: "", freeSpaceIndex: -1});
        document.querySelector(".free-space-active")?.classList.remove("free-space-active");
        document.querySelector(".free-space-set")?.classList.remove("free-space-set");
    }

    updateBoxes() {
        for (let i = 0; i < 25; i++) {
            const box = document.querySelector(`#bingo-box-${i}`) as HTMLDivElement;
            box.innerText = "";
        }

        const freeBox = document.querySelector(`#bingo-box-12`) as HTMLDivElement;
        freeBox.innerText = this.state.freeSpace;


        let lastBox = 0;
        for (let i = 0; i < Math.min(25, this.state.termArray.length); i++) {
            const term = this.state.termArray[i];
            if (i === this.state.freeSpaceIndex) continue;
            if (lastBox === 12 && this.state.freeSpaceIndex !== -1) lastBox++;
            if (lastBox > 24) break;
            const box = document.querySelector(`#bingo-box-${lastBox++}`) as HTMLDivElement;
            box.innerText = term;
        }
    }

    termStartHover(term: string) {
        const boxes = document.querySelector("#bingo-grid")?.childNodes;
        if (boxes === undefined) return;
        for (let i = 0; i < boxes.length; i++) {
            const box = boxes[i] as HTMLLIElement;
            if (box.innerText === term) {
                box.classList.add("bingo-box-hover");
                return;
            }
        }
    }

    termEndHover() {
        document.querySelector(".bingo-box-hover")?.classList.remove("bingo-box-hover");
    }

    handleTitleChange(title: string) {
        if (title === "") {
            this.setState({title: "BINGO"});
            return;
        }
        this.setState({title: title});
    }

    handleNumberOfPagesChange(numberOfPages: string) {
        if (numberOfPages === "" || isNaN(parseInt(numberOfPages))) {
            this.setState({numberOfPages: 1});
            (document.querySelector("#number-of-pages-input") as HTMLInputElement).value = "1";
        } else if (parseInt(numberOfPages) < 1 || parseInt(numberOfPages) > 128) {
            let val = Math.min(Math.max(parseInt(numberOfPages), 1), 128);
            this.setState({numberOfPages: val});
            (document.querySelector("#number-of-pages-input") as HTMLInputElement).value = `${val}`;
            return;
        }
        this.setState({numberOfPages: parseInt(numberOfPages)});

    }

    handleKeyDownPages(event: { key: string, target: EventTarget }): void {
        if (event.key === 'Enter') {
            (event.target as HTMLInputElement)?.blur();
        }
    }

    generatePDF() {
        if (this.state.termArray.length < 25) {
            const alert = document.createElement("div");
            alert.innerText = "You need at least 25 terms to create a bingo card";
            alert.classList.add("alert");
            document.querySelector("#root")?.appendChild(alert);
            setTimeout(() => alert.remove(), 4000);
            return;
        }

        {
            let oldPreviewer = document.querySelector("#bingo-pdf-preview");
            if (oldPreviewer) {
                URL.revokeObjectURL((oldPreviewer as HTMLIFrameElement).src);
                oldPreviewer.remove();
            }

            const bingoCard = document.querySelector("#bingo-card-container") as HTMLElement;
            const bingoCardClone = bingoCard.cloneNode(true) as HTMLElement;
            bingoCardClone.style.color = 'black';
            bingoCardClone.style.backgroundColor = 'white';
            bingoCardClone.style.border = 'none';
            const freeSpace = bingoCardClone.childNodes[1].childNodes[12] as HTMLElement;
            const bingoGrid = bingoCardClone.childNodes[1] as HTMLElement;
            bingoGrid.childNodes.forEach((box: ChildNode) => {
                    (box as HTMLElement).style.border = '2px solid black';
                }
            );
            freeSpace.style.boxShadow = 'none';
        }

        const numPages = this.state.numberOfPages;
        const freeSpaceIndex = this.state.freeSpaceIndex;

        const uniqueGridWords = new Map<number, string[]>();
        for (let i = 0; i < numPages; i++) {
            let availableTerms = this.state.termArray.slice();
            if (freeSpaceIndex !== -1)
                availableTerms.splice(freeSpaceIndex, 1);

            const gridWordList = [];
            for (let j = 0; j < 25; j++) {
                if (j === 12 && freeSpaceIndex !== -1) {
                    gridWordList.push(this.state.termArray[freeSpaceIndex]);
                    continue;
                }
                const randomIndex = Math.floor(Math.random() * availableTerms.length);
                gridWordList.push(availableTerms[randomIndex]);
                availableTerms.splice(randomIndex, 1);
            }


            let code = BingoCardMaker.gridHashCode(gridWordList);
            if (uniqueGridWords.has(code)) {
                i--;
                continue;
            }
            uniqueGridWords.set(code, gridWordList);
        }

        let docDefinition = {
            pageSize: 'A4',
            pageMargins: 20,
            defaultStyle: {
                alignment: 'center',
                valign: 'middle',
            }
        }
        let content = [];
        const wordArr = Array.from(uniqueGridWords.values());
        content.push(
            { text: this.state.title, alignment: 'center', fontSize: 36, bold: true },
            {
                table: {
                    widths: [102, 102, 102, 102, 102],
                    heights: [102, 102, 102, 102, 102],
                    body: [
                        BingoCardMaker.tableRowFromArr(wordArr[0].slice(0, 5)),
                        BingoCardMaker.tableRowFromArr(wordArr[0].slice(5, 10)),
                        BingoCardMaker.tableRowFromArr(wordArr[0].slice(10, 15)),
                        BingoCardMaker.tableRowFromArr(wordArr[0].slice(15, 20)),
                        BingoCardMaker.tableRowFromArr(wordArr[0].slice(20, 25)),
                    ],

                }
            },

        );

        for (let i = 1; i < wordArr.length; i++) {
            content.push(
                { text: this.state.title, alignment: 'center', fontSize: 36, bold: true, pageBreak: 'before' },
                {
                    table: {
                        widths: [102, 102, 102, 102, 102],
                        heights: [102, 102, 102, 102, 102],
                        body: [
                            BingoCardMaker.tableRowFromArr(wordArr[i].slice(0, 5)),
                            BingoCardMaker.tableRowFromArr(wordArr[i].slice(5, 10)),
                            BingoCardMaker.tableRowFromArr(wordArr[i].slice(10, 15)),
                            BingoCardMaker.tableRowFromArr(wordArr[i].slice(15, 20)),
                            BingoCardMaker.tableRowFromArr(wordArr[i].slice(20, 25)),
                        ],

                    }
                },
            );
        }

        // @ts-ignore
        docDefinition.content = content;

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.getBlob((blob: Blob) => {
            const url = URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');
            iframe.width = '100%';
            iframe.style.aspectRatio = '17/22';
            iframe.src = url;
            iframe.id = "bingo-pdf-preview";
            document.querySelector("#bingo-right")?.appendChild(iframe);
        });


    }

    static tableRowFromArr(arr: string[]): Object[] {
        const result = [];
        for (let i = 0; i < arr.length; i++) {
            result.push({ text: arr[i], margin: [0, 42, 0, 42] });
        }
        return result;
    }


    static gridHashCode(gridWordList: string[]): number {
        let hash = 0;
        for (let i = 0; i < gridWordList.length; i++) {
            hash = gridWordList[i].length + ((hash << 5) - hash);
            for (let j = 0; j < gridWordList[i].length; j++) {
                const char = gridWordList[i].charCodeAt(j);
                hash = char + ((hash << 5) - hash);
            }
        }
        return hash;
    }


    render() {

        let boxes = [];
        for (let i = 0; i < 25; i++) {
            boxes.push(<li key={i.toString()} id={`bingo-box-${i}`} className={"bingo-box"}></li>);
        }

        return (
            <>
                <Navbar isProjectSubpage={true}/>
                <div id={"bingo-container"}>
                    <div id={"bingo-term-setting-group"}>
                        <div id={"bingo-settings-container"}>
                            <span id={"term-title"}>
                                Terms
                            </span>
                            <div id={"add-term-container"}>
                                <input id={"bingo-term-input"} className={"bingo-text-box"} type={"text"} autoComplete={'off'} role="presentation" onKeyDown={(e) => this.handleKeyDown(e)}/>
                                <button id={"bingo-term-add"} className={"bingo-button"} onClick={() => this.addTerm()}>ADD</button>
                            </div>

                            <ul id={"full-term-list"}>
                                {this.state.termArray.map((term, index) => (
                                    <li key={index}
                                        onMouseEnter={() => this.termStartHover(term)}
                                        onMouseLeave={() => this.termEndHover()}
                                        className={"term-list-item"}
                                    >
                                        <button className={"remove-term-button"} onClick={() => this.removeTerm(term)}>✖</button>
                                        <span className={"term-text"}>{term}</span>
                                        <button className={"free-space-button"}
                                                onClick={() => this.setFreeSpace(term, index)}
                                        ></button>
                                    </li>
                                ))
                                }
                            </ul>
                        </div>
                        <div id={"bingo-right"}>
                            <input id={"bingo-title-input"}
                                   className={"bingo-text-box"}
                                   type={"text"}
                                   placeholder={"Title"}
                                   onChange={(e) => this.handleTitleChange(e.target.value)}
                                   autoComplete={"off"}
                            />
                            <div id={"bingo-card-container"}>
                                <span id={"bingo-card-title"}>{this.state.title}</span>
                                <ul id={"bingo-grid"}>
                                    {boxes}
                                </ul>
                            </div>
                            <button
                                id={"bingo-create-button"}
                                className={"bingo-button"}
                                onClick={() => this.generatePDF()}>
                                EXPORT
                            </button>
                            <div id={"bingo-export-settings"}>
                                <span>Number of unique pages:</span>
                                <input id={"number-of-pages-input"}
                                       className={"bingo-text-box"}
                                       type={"number"}
                                       min={1}
                                       max={128}
                                       defaultValue={1}
                                       onBlur={(e) => this.handleNumberOfPagesChange(e.target.value)}
                                       onKeyDown={(e) => this.handleKeyDownPages({key: e.key, target: e.target})}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </>
        );
    }
}


export default BingoCardMaker;