import React from "react";
import '../../../assets/css/secondary-page.css';
import '../../../assets/css/all-pages.css';
import Navbar from '../../../components/navbar';
import rightUpArrow from "../../../assets/images/right-up-arrow.svg";
import {Link, useNavigate} from "react-router-dom";


function CSPortfolio() {
    const nextCard = function() {
        const allCards = document.querySelectorAll(".cybersecurity-portfolio-page");
        const activeCard = document.getElementsByClassName("active-cybersecurity-page")[0];
        const index = Array.prototype.indexOf.call(allCards, activeCard);
        const nextCard = allCards[index + 1];
        if (nextCard) {
            document.querySelector(".active-card-indicator")?.classList.remove("active-card-indicator");
            document.querySelectorAll(".card-indicator")[index + 1].classList.add("active-card-indicator");
            activeCard.classList.remove("active-cybersecurity-page");
            nextCard.classList.add("active-cybersecurity-page");
        }
    }

    const previousCard = function() {
        const allCards = document.querySelectorAll(".cybersecurity-portfolio-page");
        const activeCard = document.getElementsByClassName("active-cybersecurity-page")[0];
        const index = Array.prototype.indexOf.call(allCards, activeCard);
        const previousCard = allCards[index - 1];
        if (previousCard) {
            document.querySelector(".active-card-indicator")?.classList.remove("active-card-indicator");
            document.querySelectorAll(".card-indicator")[index - 1].classList.add("active-card-indicator");
            activeCard.classList.remove("active-cybersecurity-page");
            previousCard.classList.add("active-cybersecurity-page");
        }
    }

    return (
        <>
            <Navbar isProjectSubpage={true}/>
            <div id={"cybersecurity-portfolio-scroller-container"}>
                <div id={"cybersecurity-portfolio-scroller"}>
                    <div className={"cybersecurity-portfolio-page active-cybersecurity-page"}>
                        <span className={"cybersecurity-portfolio-page-title"}>CIA Triad Case Study</span>
                        <span className={"cybersecurity-portfolio-page-subtitle"}>U1: What is Cybersecurity?</span>
                        <span className={"cybersecurity-portfolio-page-text"}>
                            A case study on the 2013 Target data breach and an
                            analysis of the CIA triad during this incident.
                        </span>
                        <Link
                            className={"cybersecurity-portfolio-page-link"}
                            to={"https://docs.google.com/document/d/1WCB2vG99S6YVJUfBbcO04xe8jOrSXALbtJIGqTdcRhg/edit?usp=sharing"}
                            target={"_blank"}
                        >
                            <span className={"project-button-title"}>Open In New Tab</span>
                            <img className={"project-button-icon cybersecurity-portfolio-page-link-icon"} alt="link" src={rightUpArrow}/>
                        </Link>
                    </div>
                    <div className={"cybersecurity-portfolio-page"}>
                        <span className={"cybersecurity-portfolio-page-title"}>Mirai High School Analysis</span>
                        <span className={"cybersecurity-portfolio-page-subtitle"}>U2: Privacy and Security</span>
                        <span className={"cybersecurity-portfolio-page-text"}>
                            An risk assessment of a fictional school named Mirai High School.
                            Assets, threats, vulnerabilities, and countermeasures are identified.
                        </span>
                        <Link
                            className={"cybersecurity-portfolio-page-link"}
                            to={"https://docs.google.com/document/d/1hYo-PhZRIcn_GDunToQ9E6pHl64VM9KHuZXmsln3ArU/edit?usp=sharing"}
                            target={"_blank"}
                        >
                            <span className={"project-button-title"}>Open In New Tab</span>
                            <img className={"project-button-icon cybersecurity-portfolio-page-link-icon"} alt="link" src={rightUpArrow}/>
                        </Link>
                    </div>
                    <div className={"cybersecurity-portfolio-page"}>
                        <span className={"cybersecurity-portfolio-page-title"}>Phishing Lab</span>
                        <span className={"cybersecurity-portfolio-page-subtitle"}>U3: Linux & US Cyber range</span>
                        <span className={"cybersecurity-portfolio-page-text"}>
                            Used Kali Linux to create a phishing attack on a virtual machine using a
                            fake Google login page. The attack was successful and the credentials were
                            captured.
                        </span>
                        <Link
                            className={"cybersecurity-portfolio-page-link"}
                            to={"https://drive.google.com/file/d/1XhumjBC76rcbC9fhffakdLGbjEBRlVEp/view?usp=sharing"}
                            target={"_blank"}
                        >
                            <span className={"project-button-title"}>Open In New Tab</span>
                            <img className={"project-button-icon cybersecurity-portfolio-page-link-icon"} alt="link" src={rightUpArrow}/>
                        </Link>
                    </div>
                    <div className={"cybersecurity-portfolio-page"}>
                        <span className={"cybersecurity-portfolio-page-title"}>Password Hashing Activity</span>
                        <span className={"cybersecurity-portfolio-page-subtitle"}>U4: Cryptography</span>
                        <span className={"cybersecurity-portfolio-page-text"}>
                            Created several hashes for common passwords using the MD5 and SHA-1 hashing
                            algorithms. A rainbow table was used to successfully crack the hashes.
                        </span>
                        <Link
                            className={"cybersecurity-portfolio-page-link"}
                            to={"https://docs.google.com/document/d/1N4BNw9bWU1AGhv8ee44P0jkqsiTDHkSIwFTFwfhevOE/edit?usp=sharing"}
                            target={"_blank"}
                        >
                            <span className={"project-button-title"}>Open In New Tab</span>
                            <img className={"project-button-icon cybersecurity-portfolio-page-link-icon"} alt="link" src={rightUpArrow}/>
                        </Link>
                    </div>
                    <div className={"cybersecurity-portfolio-page"}>
                        <span className={"cybersecurity-portfolio-page-title"}>Network Ports</span>
                        <span className={"cybersecurity-portfolio-page-subtitle"}>U5: Threats, Vulnerabilities, and Attacks</span>
                        <span className={"cybersecurity-portfolio-page-text"}>
                            Researched common network ports and their associated protocols and usages.

                        </span>
                        <Link
                            className={"cybersecurity-portfolio-page-link"}
                            to={"https://docs.google.com/document/d/1NRGmrkxDzhXS_qAIAaNuPqedaSE1h44x3goAYA5DHwA/edit?usp=sharing"}
                            target={"_blank"}
                        >
                            <span className={"project-button-title"}>Open In New Tab</span>
                            <img className={"project-button-icon cybersecurity-portfolio-page-link-icon"} alt="link" src={rightUpArrow}/>
                        </Link>
                    </div>
                    <div id={"cybersecurity-portfolio-scroller-controls"}>
                        <button id={"card-previous"} className={"card-control-button"} onClick={previousCard}></button>
                        <div className={"card-indicator cybersecurity-project-indicator active-card-indicator "}></div>
                        <div className={"card-indicator cybersecurity-project-indicator"}></div>
                        <div className={"card-indicator cybersecurity-project-indicator"}></div>
                        <div className={"card-indicator cybersecurity-project-indicator"}></div>
                        <div className={"card-indicator cybersecurity-project-indicator"}></div>
                        <button id={"card-next"} className={"card-control-button"} onClick={nextCard}></button>
                    </div>
                </div>
            </div>
        </>

    );
}

export default CSPortfolio;