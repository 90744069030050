import React from 'react';
import '../assets/css/all-pages.css';
import '../assets/css/secondary-page.css';
import Navbar from '../components/navbar';
import NVLogo from '../assets/images/NVLogo.svg';
import UICLogo from '../assets/images/uic-logo.svg';
import csLogo from '../assets/images/cs-logo.svg';
import javaLogo from '../assets/images/java-logo.svg';
import jsLogo from '../assets/images/js-logo.svg';
import tsLogo from '../assets/images/ts-logo.svg';
import htmlLogo from '../assets/images/html-logo.svg';
import cssLogo from '../assets/images/css-logo.svg';
import reactLogo from '../assets/images/react-logo.svg';
import gitHubLogo from '../assets/images/github-logo.svg';
import unityLogo from '../assets/images/unity-logo.svg';
import forgeLogo from '../assets/images/forge-logo.svg';
import {Link} from "react-router-dom";
import linkIcon from '../assets/images/link-icon.svg';
import boxesIcon from '../assets/images/boxes.svg';
import worldIcon from '../assets/images/world.svg';
import gearIcon from '../assets/images/gear.svg';
import joystickIcon from '../assets/images/joystick.svg';
import chipIcon from '../assets/images/chip.svg';
import monitorIcon from '../assets/images/monitor.svg';


function About() {
    const scrollPage = function() {
        document.getElementById("about-card-container")?.scrollIntoView({behavior: "smooth"});
    };

    const nextCard = function() {
        const allCards = document.querySelectorAll(".about-card-page");
        const activeCard = document.getElementsByClassName("active-card")[0];
        const index = Array.prototype.indexOf.call(allCards, activeCard);
        const nextCard = allCards[index + 1];
        if (nextCard) {
            document.querySelector(".active-card-indicator")?.classList.remove("active-card-indicator");
            document.querySelectorAll(".card-indicator")[index + 1].classList.add("active-card-indicator");
            activeCard.classList.remove("active-card");
            activeCard.classList.add("no-select");
            nextCard.classList.add("active-card");
            nextCard.classList.remove("no-select");
        }
    }

    const previousCard = function() {
        const allCards = document.querySelectorAll(".about-card-page");
        const activeCard = document.getElementsByClassName("active-card")[0];
        const index = Array.prototype.indexOf.call(allCards, activeCard);
        const previousCard = allCards[index - 1];
        if (previousCard) {
            document.querySelector(".active-card-indicator")?.classList.remove("active-card-indicator");
            document.querySelectorAll(".card-indicator")[index - 1].classList.add("active-card-indicator");
            activeCard.classList.remove("active-card");
            activeCard.classList.add("no-select");
            previousCard.classList.add("active-card");
            previousCard.classList.remove("no-select");
        }
    }

    return(
        <>
            <div id={"landing-screen-container"}>
                <Navbar isProjectSubpage={false}/>
                <div id="about-header">
                    <span id={"about-header-subtitle"}>Hi, my name is</span>
                    <div className={"gradient-border"}>
                        <span id={"about-header-title"} className={"gradient-text"}>Luke Abraham</span>
                    </div>
                    <div id={"screen-down-button-container"}>
                        <button id={"screen-down-button"} onClick={scrollPage}/>
                    </div>
                </div>
            </div>
            <div id={"about-card-container"}>
                <div id={"about-card"}>
                    <div className={"about-card-page active-card"}>
                        <span className={"about-card-title"}>Education</span>
                        <div className={"about-card-right"}>
                            <img className={"about-card-icon"} src={UICLogo} alt={"UIC Logo"}/>
                        </div>
                        <span className={"about-card-text"}>
                            <span>
                                I recently graduated from <span className={"text-blue"}>Neuqua Valley High School </span>
                                and am planning to major in computer engineering at <span className={"text-blue"}>UIC</span>
                            </span>
                        </span>
                    </div>
                    <div className={"about-card-page"}>
                        <span className={"about-card-title"}>Skills</span>
                        <div className={"about-card-right"}>
                            <span id={"about-icon-container"} className={"about-box-text"}>
                                <div className={"about-icon-group"}>
                                    <span className={"icon-grid-subtitle"}>Languages</span>
                                    <div className={"about-box-icon-grid"}>
                                        <div data-name={"C#"}><img src={csLogo} alt={"C#"}/></div>
                                        <div data-name={"Java"}><img src={javaLogo} alt={"Java"}/></div>
                                        <div data-name={"JavaScript"}><img src={jsLogo} alt={"JavaScript"}/></div>
                                        <div data-name={"TypeScript"}><img src={tsLogo} alt={"TypeScript"}/></div>
                                        <div data-name={"HTML"}><img src={htmlLogo} alt={"HTML"}/></div>
                                        <div data-name={"CSS"}><img src={cssLogo} alt={"CSS"}/></div>
                                    </div>
                                </div>
                                <div className={"about-icon-group"}>
                                    <span className={"icon-grid-subtitle"}>Tools</span>
                                    <div className={"about-box-icon-grid"}>
                                        <div data-name={"GitHub"}><img src={gitHubLogo} alt={"GitHub"}/></div>
                                    </div>
                                </div>
                                <div className={"about-icon-group"}>
                                    <span className={"icon-grid-subtitle"}>Frameworks</span>
                                    <div className={"about-box-icon-grid"}>
                                        <div data-name={"React"}><img src={reactLogo} alt={"React"}/></div>
                                        <div data-name={"Unity"}><img src={unityLogo} alt={"Unity"}/></div>
                                        <div data-name={"Forge (Minecraft)"}><img src={forgeLogo} alt={"Forge (Minecraft)"}/></div>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <span className={"about-card-text"}>
                            The languages, tools, and frameworks I have the most experience with.
                            <br/>
                            <br/>
                            Check out my projects using these technologies here:
                            <Link className={"link"} to={"/projects"}><img alt={"Link"} src={linkIcon}/> Projects</Link>
                        </span>
                    </div>
                    <div className={"about-card-page"}>
                        <span className={"about-card-title"}>Interests</span>
                        <div className={"about-card-right"}>
                            <ul id={"about-interest-list"}>
                                <li><img src={boxesIcon} alt={"Low-Level Programming"}/>Low-Level Programming</li>
                                <li><img src={worldIcon} alt={"Web Development"}/>Web Development</li>
                                <li><img src={gearIcon} alt={"Software Engineering"}/>Software Engineering</li>
                                <li><img src={joystickIcon} alt={"Video Game Development"}/>Video Game Development</li>
                                <li><img src={chipIcon} alt={"Computer Architecture"}/>Computer Architecture</li>
                                <li><img src={monitorIcon} alt={"UI Design"}/>UI Design</li>
                            </ul>
                        </div>
                        <span className={"about-card-text"}>
                            <span>
                                Some of the topics that I am interested in
                            </span>
                        </span>
                    </div>
                    <div id={"card-scroller"}>
                        <button id={"card-previous"} className={"card-control-button"} onClick={previousCard}></button>
                        <div className={"card-indicator active-card-indicator"}></div>
                        <div className={"card-indicator"}></div>
                        <div className={"card-indicator"}></div>
                        {/*<div className={"card-indicator"}></div>*/}
                        <button id={"card-next"} className={"card-control-button"} onClick={nextCard}></button>
                    </div>
                </div>
            </div>

        </>
    );
}

export default About;