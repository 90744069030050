import React from "react";
import '../../../assets/css/secondary-page.css';
import '../../../assets/css/all-pages.css';
import '../../../assets/css/cybersecurity-project.css';
import Navbar from '../../../components/navbar';
import rightArrow from "../../../assets/images/right-arrow.svg";
import { useNavigate } from "react-router-dom";


function CSHome() {
    const navigate = useNavigate();
    return (
        <>
            <Navbar isProjectSubpage={true}/>
            <div className={"cybersecurity-project-header-container"}>
                <span className="cybersecurity-project-header">Cybersecurity Portfolio</span>
            </div>
            <div id={"cybersecurity-about-me-container"}>
                <span id="cybersecurity-about-me-title">About Me</span>
                <div id={"cybersecurity-about-me-text"}>
                    Hi! I'm a high school senior at Neuqua Valley and I'm planning
                    to pursue computer engineering at UIC. I have expertise in
                    software, video game, and web development. I enjoy exploring
                    the mechanics of the world. particularly in physics and math.
                    Collaboration is one of my strong suits, and my objective
                    is to develop applications that enhance efficiency and save
                    time for individuals.
                </div>
                <div className="cs-button-container">
                    <div
                        className={"cybersecurity-view-portfolio-button"}
                        onClick={() => navigate("/projects/cybersecurity-project/portfolio")}
                    >
                        <span className={"project-button-title"}>View Portfolio</span>
                        <img className={"project-button-icon"} alt="link" src={rightArrow}/>
                    </div>
                    <div
                        className={"cybersecurity-view-portfolio-button"}
                        onClick={() => navigate("/projects/cybersecurity-project/infographic")}
                    >
                        <span className={"project-button-title"}>View Infographic</span>
                        <img className={"project-button-icon"} alt="link" src={rightArrow}/>
                    </div>
                </div>
                
            </div>
        </>

    );
}

export default CSHome;