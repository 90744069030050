import React from 'react';
import '../assets/css/all-pages.css';
import '../assets/css/home-page.css';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

function Home() {
    const doProjectsHoverEffect = () => {
        const elements = document.querySelectorAll(".home-card-column .home-card:nth-child(odd)");
        for (let i = 0; i < elements.length; i++) {
            (elements[i].firstElementChild as HTMLElement).style.opacity = "1";
        }
    };

    const undoProjectsHoverEffect = async () => {
        const elements = document.querySelectorAll(".home-card-column .home-card:nth-child(odd)");
        for (let i = 0; i < elements.length; i++) {
            (elements[i].firstElementChild as HTMLElement).style.opacity = "0";
        }
    }

    const doAboutHoverEffect = () => {
        const elements = document.querySelectorAll(".home-card-column .home-card:nth-child(even)");
        for (let i = 0; i < elements.length; i++) {
            (elements[i].firstElementChild as HTMLElement).style.opacity = "1";
        }
    }

    const undoAboutHoverEffect = async () => {
        const elements = document.querySelectorAll(".home-card-column .home-card:nth-child(even)");
        for (let i = 0; i < elements.length; i++) {
            (elements[i].firstElementChild as HTMLElement).style.opacity = "0";
        }
    }

    return (
        <>
            <Helmet>
                <meta name="apple-mobile-web-app-capable" content="yes"/>
                <meta name="mobile-web-app-capable" content="yes"/>
            </Helmet>
            <div id={"home-page-container"}>
                <div id={"name-title-container"}>
                    <span id={"name-title"} className={"title-text"}>Luke Abraham</span>
                    <Link
                        className={"title-text title-link"}
                        to={"/projects"}
                        onMouseEnter={doProjectsHoverEffect}
                        onMouseLeave={undoProjectsHoverEffect}
                    >
                        <div className={"title-bullet no-select"}/>
                        Projects
                    </Link>
                    <Link
                        className={"title-text title-link"}
                        to={"/about"}
                        onMouseEnter={doAboutHoverEffect}
                        onMouseLeave={undoAboutHoverEffect}
                    >
                        <div className={"title-bullet no-select"}/>
                        About
                    </Link>
                    <div id={"spacer"}></div>
                </div>
                <div id={"home-card-container"}>
                    <div className={"home-card-column"}>
                        <div className={"home-card"}><div/></div>
                        <div className={"home-card"}><div/></div>
                        <div className={"home-card"}><div/></div>
                        <div className={"home-card"}><div/></div>
                    </div>
                    <div className={"home-card-column"}>
                        <div className={"home-card"}><div/></div>
                        <div className={"home-card"}><div/></div>
                        <div className={"home-card"}><div/></div>
                    </div>
                </div>
            </div>
        </>
  );
}

export default Home;
