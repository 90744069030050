import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Projects from './pages/projects';
import About from './pages/about';
import DeckGenerator from './pages/projects/deckGenerator';
import SortingVisualizer from "./pages/projects/sorting";
import BingoCardMaker from "./pages/projects/bingoCardMaker";
import './assets/scripts/mobileSizer.ts';
import CSInfographic from './pages/projects/cybersecurity-project/CSInfographic';
import CSPortfolio from "./pages/projects/cybersecurity-project/CSPortfolio";
import CSHome from "./pages/projects/cybersecurity-project/CSHome";

function App() {
  return (
  <Routes>
    <Route path="/" element={<Home/>}/>
    <Route path="/projects" element={<Projects/>}/>
    <Route path="/projects/deck-generator" element={<DeckGenerator/>}/>
    <Route path="/projects/sorting-visualizer" element={<SortingVisualizer/>}/>
    <Route path="/projects/bingo-card-maker" element={<BingoCardMaker/>}/>
    <Route path="/projects/cybersecurity-project" element={<CSHome/>}/>
    <Route path="/projects/cybersecurity-project/portfolio" element={<CSPortfolio/>}/>
    <Route path='/projects/cybersecurity-project/infographic' element={<CSInfographic/>}/>
    <Route path="/about" element={<About/>}/>
    <Route path="*" element={<Home/>}/>
  </Routes>);
}

export default App;
