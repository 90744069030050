import React, { Component } from 'react';
import '../../assets/css/all-pages.css';
import '../../assets/css/secondary-page.css';
import '../../assets/css/sorting-visualizer.css';
import Navbar from '../../components/navbar';
import { nanoid } from 'nanoid'

class SortingVisualizer extends Component<{},
    {
        arr: number[],
        arrSize: number,
        valueLowerBound: number,
        valueUpperBound: number,
        sortStepTimeMs: number,
        time: number,
        swapCount: number,
    }
> {

    animationKey: string;
    startTime: number;
    swapCount: number;
    comparisonCount: number;
    isCurrentlySorting: boolean;
    elementMargin: number;

    constructor(props: any) {
        super(props);

        this.state = {
            arr: [],
            arrSize: 60,
            valueLowerBound: 20,
            valueUpperBound: 100,
            sortStepTimeMs: 0,
            time: 0,
            swapCount: 0,
        };

        this.animationKey = nanoid();
        this.startTime = 0;
        this.swapCount = 0;
        this.comparisonCount = 0;
        this.isCurrentlySorting = false;
        this.elementMargin = this.calculateElementMargin();

    }

    calculateElementMargin(): number {
        return Math.round(1 / this.state.arrSize * 50);
    }

    componentDidMount(): void {
        this.randomizeArray();
    }

    randomizeArray(): void {
        if (this.isCurrentlySorting) return;
        this.elementMargin = this.calculateElementMargin();
        const arr = [];
        for (let i = 0; i < this.state.arrSize; i++) {
            arr.push(this.randomInt(this.state.valueLowerBound, this.state.valueUpperBound - this.state.valueLowerBound));
        }
        this.animationKey = nanoid();
        this.setState({
            arr: arr,
        });
        setTimeout(() => {
            if (document.getElementsByClassName("array-element")[0].clientWidth < document.getElementsByClassName("array-value-text")[0].clientWidth) {
                let textElements = document.getElementsByClassName("array-value-text");
                for (let i = 0; textElements.length; i++) {
                    textElements[i].classList.add("display-none");
                }
            }
        });
    }

    render() {
        const arr = this.state.arr;

        return (
            <>
                <Navbar isProjectSubpage={true}/>
                <div className={"settings-container"}>
                    <h1 id={"settings-title"}>Settings</h1>
                    <button onClick={() => this.randomizeArray()} id={"randomize-button"} className={"button"}>
                        Randomize
                    </button>
                    <button className={"button"} id={"bubble-sort-button"} onClick={() => this.runSortMethod(this.bubbleSort)}>Bubble Sort</button>
                    <button className={"button"} id={"selection-sort-button"} onClick={() => this.runSortMethod(this.selectionSort)}>Selection Sort</button>
                    <button className={"button"} id={"insertion-sort-button"} onClick={() => this.runSortMethod(this.insertionSort)}>Insertion Sort</button>
                    <button className={"button"} id={"merge-sort-button"} onClick={() => this.runSortMethod(this.mergeSort)}>Merge Sort</button>
                    <button className={"button"} id={"quick-sort-button"} onClick={() => this.runSortMethod(this.quickSort)}>Quick Sort</button>
                    <span className={"slider-container"} id={"delay-slider-container"}>
                        <span className={"slider-label"}>Delay: {this.state.sortStepTimeMs} ms</span>
                        <input className={"slider"} id={"delay-slider"} type={"range"} defaultValue={"0"} min={"0"} max={"500"} onChange={(e) => {this.setState({sortStepTimeMs: e.target.valueAsNumber})}}/>
                    </span>
                    <span className={"slider-container"} id={"count-slider-container"}>
                        <span className={"slider-label"}>Items: {this.state.arrSize}</span>
                        <input className={"slider"} id={"count-slider"} type={"range"} defaultValue={"60"} min={"10"} max={"100"} onChange={(e) => {this.setState({arrSize: e.target.valueAsNumber})}}/>
                    </span>
                    <span className={"statistic"} id={"sort-time"}>Previous sort time: {this.state.time} ms</span>
                    <span className={"statistic"} id={"swap-count"}>Swaps: {this.state.swapCount}</span>
                    <span className={"statistic"} id={"comparison-count"}>Comparisons: {this.comparisonCount}</span>
                </div>

                <div id={"array-container"} key={this.animationKey}>
                    {arr.map((value, index) => (
                        <div key={index} className={"array-element"} style={{
                            height: value / this.state.valueUpperBound * 50 + "vh",
                            animationDelay: index * 1000 / this.state.arrSize + "ms",
                            marginLeft: this.elementMargin + "px",
                        }}>
                            <span className={"array-value-text"}>{value}</span>
                        </div>
                    ))}
                </div>
            </>
        );
    }

    async runSortMethod(sortMethod: () => void): Promise<void> {
        if (this.isCurrentlySorting) return;
        this.isCurrentlySorting = true;
        this.comparisonCount = 0;
        this.swapCount = 0;
        this.clearAnimation(document.getElementsByClassName("array-element") as HTMLCollectionOf<HTMLElement>);
        this.startTime = Date.now();
        await sortMethod.call(this);
        this.setState({time: Date.now() - this.startTime});
        this.isCurrentlySorting = false;
    }

    randomInt(start: number, rangeSize: number): number {
        return start + Math.floor(Math.random() * (rangeSize + 1));
    }

    swap(arr: number[], index1: number, index2: number): void {
        const temp = arr[index1];
        arr[index1] = arr[index2];
        arr[index2] = temp;
        this.swapCount++;
        this.setState({swapCount: this.swapCount});
    }

    swapElements(boxes: HTMLCollectionOf<Element>, index1: number, index2: number): void {
        const temp = boxes[index1].cloneNode(true);
        boxes[index1].parentNode?.replaceChild(boxes[index2].cloneNode(true), boxes[index1]);
        boxes[index2].parentNode?.replaceChild(temp, boxes[index2]);
    }

    compare(a: number, b: number): boolean {
        this.comparisonCount++;
        this.setState({});
        return a > b;
    }

    clearAnimation(collection: HTMLCollectionOf<HTMLElement>): void {
        for (let i = 0; i < collection.length; i++) {
            collection[i].style.animation = "none";
            collection[i].style.opacity = "1";
        }
    }

    resetColor(elements: HTMLElement[]): void {
        for (let i = 0; i < elements.length; i++){
            elements[i].style.backgroundColor = "#66339a";
        }
    }

    swapColor(elements: HTMLElement[]): void {
        for (let i = 0; i < elements.length; i++){
            elements[i].style.backgroundColor = "red";
        }
    }

    compareColor(elements: HTMLElement[]): void {
        for (let i = 0; i < elements.length; i++){
            elements[i].style.backgroundColor = "green";
        }
    }

    wait(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async bubbleSort(): Promise<void> {
        const arr = this.state.arr;
        const boxes = document.getElementsByClassName("array-element");

        let swapped = true;
        let pass = 0;
        while (swapped) {
            swapped = false;
            for (let i = 0; i < arr.length - 1 - pass; i++) {
                this.compareColor([boxes[i], boxes[i+1]] as HTMLElement[]);
                await this.wait(this.state.sortStepTimeMs);
                if (this.compare(arr[i], arr[i + 1])) {
                    this.swapColor([boxes[i], boxes[i+1]] as HTMLElement[]);
                    await this.wait(this.state.sortStepTimeMs);
                    this.swap(arr, i, i + 1);
                    this.swapElements(boxes, i, i + 1);
                    swapped = true;
                }
                this.resetColor([boxes[i], boxes[i+1]] as HTMLElement[]);
            }
            pass++;
        }
    }

    async selectionSort(): Promise<void> {
        const arr = this.state.arr;
        const boxes = document.getElementsByClassName("array-element");

        for (let i = 0; i < arr.length - 1; i++) {
            let maxIndex = 0;
            for (let j = 0; j < arr.length - i; j++) {
                this.compareColor([boxes[j], boxes[maxIndex]] as HTMLElement[]);
                await this.wait(this.state.sortStepTimeMs);
                // reset early before boxes[maxIndex] changes
                this.resetColor([boxes[j], boxes[maxIndex]] as HTMLElement[]);
                if (this.compare(arr[j], arr[maxIndex])) {
                    maxIndex = j;
                }

            }
            this.swapColor([boxes[maxIndex], boxes[arr.length - i - 1]] as HTMLElement[]);
            await this.wait(this.state.sortStepTimeMs);
            this.swap(arr, maxIndex, arr.length - i - 1);
            this.swapElements(boxes, maxIndex, arr.length - i - 1);
            this.resetColor([boxes[maxIndex], boxes[arr.length - i - 1]] as HTMLElement[]);
        }
    }

    async insertionSort(): Promise<void> {
        const arr = this.state.arr;
        const boxes = document.getElementsByClassName("array-element");

        for (let i = 1; i < arr.length; i++) {
            let j = i;
            while (j >= 0 && this.compare(arr[j - 1], arr[j])) {
                this.compareColor([boxes[j], boxes[j-1]] as HTMLElement[]);
                await this.wait(this.state.sortStepTimeMs);
                this.swapColor([boxes[j], boxes[j-1]] as HTMLElement[]);
                await this.wait(this.state.sortStepTimeMs);
                this.swap(arr, j, j - 1);
                this.swapElements(boxes, j, j - 1);
                this.resetColor([boxes[j], boxes[j-1]] as HTMLElement[])
                j--;
            }
        }
    }

    async mergeSort(): Promise<void> {
        let arr = this.state.arr;
        const boxes = document.getElementsByClassName("array-element");
        await this.mergeSortHelper(arr, boxes, 0, arr.length - 1);
    }

    async mergeSortHelper(arr: number[], elements: HTMLCollectionOf<Element>, startIndex: number, endIndex: number): Promise<void> {
        if (startIndex >= endIndex) {
            return;
        }

        const mid = Math.floor((startIndex + endIndex + 1) / 2);
        const minIndex1 = startIndex;
        const maxIndex1 = mid - 1;
        const minIndex2 = mid;
        const maxIndex2 = endIndex;
        await this.mergeSortHelper(arr, elements, minIndex1, maxIndex1);
        await this.mergeSortHelper(arr, elements, minIndex2, maxIndex2);
        await this.merge(arr, elements, minIndex1, maxIndex1, minIndex2, maxIndex2);
    }

    async merge(arr: number[], elements:HTMLCollectionOf<Element>, startIndex1: number, endIndex1: number, startIndex2: number, endIndex2: number): Promise<void> {
        while (startIndex1 <= endIndex1 && startIndex2 <= endIndex2) {
            this.compareColor([elements[startIndex1], elements[startIndex2]] as HTMLElement[]);
            await this.wait(this.state.sortStepTimeMs);
            this.resetColor([elements[startIndex1], elements[startIndex2]] as HTMLElement[]);
            if (!this.compare(arr[startIndex1], arr[startIndex2])) {
                startIndex1++;
            } else {
                for (let i = startIndex2; i > startIndex1; i--) {
                    this.swapColor([elements[i], elements[i-1]] as HTMLElement[]);
                    await this.wait(this.state.sortStepTimeMs);
                    this.swap(arr, i, i - 1);
                    this.swapElements(elements, i, i - 1);
                    this.resetColor([elements[i], elements[i-1]] as HTMLElement[]);
                }
                startIndex1++;
                endIndex1++;
                startIndex2++;
            }
        }
    }

    async quickSort(): Promise<void> {
        let arr = this.state.arr;
        const boxes = document.getElementsByClassName("array-element");
        await this.quickSortHelper(arr, boxes, 0, arr.length - 1);

    }

    async quickSortHelper(arr: number[], elements: HTMLCollectionOf<Element>, startIndex: number, endIndex: number): Promise<void> {
        if (startIndex >= endIndex) {
            return;
        }
        const pivotIndex = await this.partition(arr, elements, startIndex, endIndex);
        await this.quickSortHelper(arr, elements, startIndex, pivotIndex - 1);
        await this.quickSortHelper(arr, elements, pivotIndex + 1, endIndex);
    }

    async partition(arr: number[], elements: HTMLCollectionOf<Element>, startIndex: number, endIndex: number): Promise<number> {
        const pivot = arr[endIndex];
        let pivotIndex = startIndex;
        for (let i = startIndex; i < endIndex; i++) {
            this.compareColor([elements[i], elements[endIndex]] as HTMLElement[]);
            await this.wait(this.state.sortStepTimeMs);
            this.resetColor([elements[i], elements[endIndex]] as HTMLElement[]);
            if (this.compare(pivot, arr[i])) {
                this.swapColor([elements[i], elements[pivotIndex]] as HTMLElement[]);
                await this.wait(this.state.sortStepTimeMs);
                this.swap(arr, i, pivotIndex);
                this.swapElements(elements, i, pivotIndex);
                this.resetColor([elements[i], elements[pivotIndex]] as HTMLElement[]);
                pivotIndex++;
            }
        }
        this.swapColor([elements[pivotIndex], elements[endIndex]] as HTMLElement[]);
        await this.wait(this.state.sortStepTimeMs);
        this.swap(arr, pivotIndex, endIndex);
        this.swapElements(elements, pivotIndex, endIndex);
        this.resetColor([elements[pivotIndex], elements[endIndex]] as HTMLElement[]);
        return pivotIndex;
    }



}

export default SortingVisualizer;