import React from "react";
import '../../../assets/css/secondary-page.css';
import '../../../assets/css/all-pages.css';
import Navbar from '../../../components/navbar';
import infographic from "../../../assets/images/cybersecurity-infographic.png";

function CSInfographic() {
    return (
        <>
            <Navbar isProjectSubpage={true}/>

            <div className={"cybersecurity-infographic-container"}>
                <div className="cybersecurity-infographic-card">
                    <span className={"cybersecurity-infographic-title"}>Cybersecurity Infographic</span>
                    <div className={"cybersecurity-infographic-text"}>
                        I chose to research the career of cybersecurity engineer because
                        it is close to my major and I am interested in the field. I would
                        most enjoy trying to solve problems and find solutions to
                        cybersecurity issues. I would least enjoy being responsible if
                        a cyber attack were to happen. Here's an infographic about a
                        cybersecurity engineer:
                    </div>
                    <img className={"cybersecurity-infographic"} alt="infographic" src={infographic}/>
                </div>
            </div>
        </>

    );
}

export default CSInfographic;