import React from 'react';
import clashRoyaleBanner from '../../assets/images/clash-royale-banner3.jpg';
import '../../assets/css/all-pages.css';
import '../../assets/css/secondary-page.css';
import '../../assets/css/deck-generator.css';
import Navbar from '../../components/navbar';
import { Helmet } from 'react-helmet';


class DeckGenerator extends React.Component {

    componentDidMount(): void {
        import(/* webpackIgnore: true */ process.env.PUBLIC_URL + '/js/deck-generator.js').then((module) => {
            module.run();
        });
    }

    render() {
        return (
            <>
                <Helmet>
                    <script src={process.env.PUBLIC_URL + "/js/deck-generator.js"} type="module"></script>
                </Helmet>
                <Navbar isProjectSubpage={true}/>
                <div className="project-header">
                    <img className="project-header-background" alt="clash royale background" src={clashRoyaleBanner}/>
                    <div className="project-header-text">
                        <h1>
                            Deck Generator
                        </h1>
                        <h3>
                            Clash Royale
                        </h3>
                    </div>
                </div>
                <div className="tool-container">
                    <div className="setting-title">
                        <form id="filter-form">
                            <div className="filter-title">
                                <div className="filter-option-container">
                                    <input type="radio" className="filter-option" name="filter" value="include"/>
                                    <span>Include</span>
                                </div>
                                </div>
                            <div className="filter-title">
                                <div className="filter-option-container">
                                    <input type="radio" className="filter-option" name="filter" value="exclude"/>
                                    <span>Exclude</span>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div id="card-filter-container">
                        <div className="filtered-cards-container">
                            <ul id="include-filter-ul"></ul>
                            <ul id="exclude-filter-ul"></ul>
                        </div>
                        <ul id="none-filter-ul">
    
                        </ul>
                        <div className="filter-hide-container" id="filter-hide-container">
                            <button id="filter-hide-button"/>
                            <span className='no-select'>
                                Minimize
                            </span>
                        </div>
                    </div>
                    <span className="setting-title">Champion</span>
                    <form className="champion-setting2">
                        <div>
                            <input type="radio" className="champion-option" name="champion" value="0"/>
                            <span className="no-select">None</span>
                        </div>
                        <div>
                            <input type="radio" className="champion-option" name="champion" value="1" defaultChecked/>
                            <span className="no-select">Random</span>
                        </div>
                        <div>
                            <input type="radio" className="champion-option" name="champion" value="2"/>
                            <span className="no-select">Guaranteed</span>
                        </div>
                    </form>
                    <button className="generate-button">GENERATE DECK</button>
                    <ul className="card-container"></ul>
                    <a id="link" href={"/"}> </a>
                </div>
            </>
        );
    }
}

export default DeckGenerator;