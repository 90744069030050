import React from "react";
import { NavLink } from "react-router-dom";

interface NavbarProps {
    isProjectSubpage?: boolean;
}

function Navbar({isProjectSubpage}: NavbarProps) { 
    return (
        <>
            <div className="navbar">
                <div className={"nav-links-container"}>
                    <NavLink id="nav-logo" className={"navbar-link gradient-text"} to="/">
                        Luke Abraham
                    </NavLink>
                    <div id="end-links-container">
                        <NavLink className={"navbar-link"} to="/">
                            Home
                        </NavLink>
                        <NavLink className={"navbar-link" + (isProjectSubpage ? " semi-active" : "")} to="/projects">
                            Projects
                        </NavLink>
                        <NavLink className={"navbar-link"} to="/about">
                            About
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Navbar;