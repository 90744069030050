import React from 'react';
import '../assets/css/all-pages.css';
import '../assets/css/secondary-page.css';
import Navbar from '../components/navbar';
import clashRoyaleBackground from '../assets/images/clash-royale.jpg';
import HTMLIcon from '../assets/images/html-logo.svg';
import CSSIcon from '../assets/images/css-logo.svg';
import JSIcon from '../assets/images/js-logo.svg';
import reactIcon from '../assets/images/react-logo.svg';
import TSIcon from '../assets/images/ts-logo.svg';
import sortingVisualizerBackground from '../assets/images/sorting-visualizer.png';
import csBackground from '../assets/images/cybersecurity-bg.png';
import rightArrow from '../assets/images/right-arrow.svg';
import bingoBackground from '../assets/images/bingo.png';
import { useNavigate } from "react-router-dom";

function Projects() {

    const navigate = useNavigate();
    return(
        <>
            <Navbar/>
            <div id={"project-list-container"}>
                <ul id={"project-list"}>
                    <li className={"project-card"}

                        style={
                            {
                                backgroundImage: `url(${csBackground})`,
                            }
                        }
                        >
                        <div className={"brighten-on-hover project-card2"}>
                            <span className={"project-title"}>Cybersecurity Project</span>
                            <span className={"project-subtitle"}>Class Portfolio</span>
                            <div className={"project-icon-container"}>
                            </div>
                            <div
                                className={"project-button"}
                                onClick={() => navigate("/projects/cybersecurity-project")}
                            >
                                <span className={"project-button-title"}>Try It</span>
                                <img className={"project-button-icon"} alt="link" src={rightArrow}/>
                            </div>
                        </div>
                    </li>
                    <li className={"project-card"}
                        style={
                            {
                                backgroundImage: `url(${sortingVisualizerBackground})`,
                                backgroundPositionX: "85%",
                            }
                        }
                    >
                        <div className={"brighten-on-hover project-card2"}>
                            <span className={"project-title"}>Sorting Algorithms</span>
                            <span className={"project-subtitle"}>Visualization</span>
                            <div className={"project-icon-container"}>
                                <img alt="html" src={HTMLIcon}/>
                                <img alt="css" src={CSSIcon}/>
                                <img alt="typescript" src={TSIcon}/>
                                <img alt="react" src={reactIcon}/>
                            </div>
                            <div
                                className={"project-button"}
                                onClick={() => navigate("/projects/sorting-visualizer")}
                            >
                                <span className={"project-button-title"}>Try It</span>
                                <img className={"project-button-icon"} alt="link" src={rightArrow}/>
                            </div>
                        </div>
                    </li>
                    <li className={"project-card"}

                        style={
                            {
                                backgroundImage: `url(${clashRoyaleBackground})`,
                                backgroundPositionX: "33%",
                            }
                        }
                    >
                        <div className={"brighten-on-hover project-card2"}>
                            <span className={"project-title"}>Random Deck Generator</span>
                            <span className={"project-subtitle"}>Clash Royale</span>
                            <div className={"project-icon-container"}>
                                <img alt="html" src={HTMLIcon}/>
                                <img alt="css" src={CSSIcon}/>
                                <img alt="javascript" src={JSIcon}/>
                            </div>
                            <div
                                className={"project-button"}
                                onClick={() => navigate("/projects/deck-generator")}
                            >
                                <span className={"project-button-title"}>Try It</span>
                                <img className={"project-button-icon"} alt="link" src={rightArrow}/>
                            </div>
                        </div>
                    </li>
                    <li className={"project-card"}

                        style={
                            {
                                backgroundImage: `url(${bingoBackground})`,
                            }
                        }
                    >
                        <div className={"brighten-on-hover project-card2"}>
                            <span className={"project-title"}>Bingo Card Maker</span>
                            <span className={"project-subtitle"}>Generate and Randomize</span>
                            <div className={"project-icon-container"}>
                                <img alt="html" src={HTMLIcon}/>
                                <img alt="css" src={CSSIcon}/>
                                <img alt={"typescript"} src={TSIcon}/>
                                <img alt="react" src={reactIcon}/>
                            </div>
                            <div
                                className={"project-button"}
                                onClick={() => navigate("/projects/bingo-card-maker")}
                            >
                                <span className={"project-button-title"}>Try It</span>
                                <img className={"project-button-icon"} alt="link" src={rightArrow}/>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
}



export default Projects;